import React from "react"
import { Link } from "gatsby"
import Collapsible from 'react-collapsible';
import Layout from "../components/layout"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Cta from "../components/cta"
import Seo from "../components/seo"
import ChevronDown from "../components/svg/chevronDown"
import ArrowRight from "../components/svg/arrowRight"

const ReactJobsPage = () => (
    <Layout>
        <Seo 
            title="React.js Jobs Sydney, Canberra Australia | Remote WFH React" 
            description="Are you a React developer? We're looking for experienced React.js developers to work with us on startup application development projects."
            pathname="/react-jobs/"
        />

        <section role="main" className="bg-purple white">
          <div className="container">
              <div className="row flex flex-jc">
                  <div className="hero-content sub-hero">
                      <h1
                      className="small-h"
                      data-sal="slide-up" 
                      data-sal-easing="ease"
                      data-sal-delay="100"
                      data-sal-duration="400"
                      >React Developer Jobs</h1>
                      <h2 className="hero-lead"
                      data-sal="slide-up" 
                      data-sal-easing="ease"
                      data-sal-delay="300"
                      data-sal-duration="400"
                      >
                          We're looking to work with experienced React / Typescript developers on startup application development projects
                      </h2>
                      <div className="btn-row">
                      <button onClick={() => scrollTo('#anchor')} className="btn btn-lg btn-pink">
                          <span className="flex flex-ac down">
                          <ArrowRight />
                          </span>
                      </button>
                      </div>
                  </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3>Remote Javascript jobs in Australia</h3>
                        </div>

                        <div className="col-67 long-text">
                            <p>Launch Lab builds <Link to="/about/">tech startups</Link> for non-technical founders in Australia. We also work with corporates, government and non-profits to develop web applications, mobile apps and websites.</p>
                            <p>We regularly have spikes in demand for <Link to="/react-developers-sydney-canberra/">React.js</Link> projects with the vast majority being for tech startups. We therefore often have remote contract / freelance job opportunities for  <strong>mid to senior React developers</strong>.</p>
                            <p>If you would like to find out more please contact us and provide either a CV or your LinkedIn profile and ideally your Github handle too.</p>
                            <p>The ideal candidate will have at least a few years experience working as a frontend developer. You'll be able to <strong>write your own Javascript</strong>. You can troubleshoot cross-browser issues and site speed and user experience is at the heart of everything you do.</p>
                            <p>Having the following skills and experience will help:</p>
                            <ul>
                                <li>High Javascript competence</li>
                                <li>Experience using React.js</li>
                                <li>GraphQL</li>
                                <li>Typescript</li>
                                <li>Up to date understanding of the available tools and best practices in the rapidly evolving Javascript ecosystem</li>
                                <li>Git</li>
                                <li>CSS knowledge</li>
                                <li>Ability to work independently</li>
                                <li>React Native experience would be very welcome, but is not a must have</li>
                            </ul>
                            <p>If this sounds like you then we'd love to hear from you. Depending on the timing we could accommodate freelance / contract or hire permanent.</p>
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Contact us
                                    <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad bg-black">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h5 className="title">
                                FAQs
                            </h5>
                        </div>
                        <div className="col-67">
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Is this a remote React developer job? <ChevronDown />
                                </>
                                }
                            >
                                <p>Yes. We'd prefer to work with React developers who reside in Australia but would be open to working with the right candidates regardless of their geographic location.</p>
                                <p>Wherever you are in the world strong spoken and written English language skills are a pre-requistite.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do I need my own laptop? <ChevronDown />
                                </>
                                }
                            >
                                <p>Yes. This is a BYO opportunity and you'll need to have modern and up to date hardware.</p>
                            </Collapsible>
                            <Collapsible 
                                transitionTime={100}
                                trigger={
                                <>
                                    Do I need React Native experience? <ChevronDown />
                                </>
                                }
                            >
                                <p>No. Most of the work we do is developing startup web applications. We do also offer React Native development so if you do have RN experience that's a plus, but it isn't a 'have to have'.</p>
                            </Collapsible>
                        </div>
                    </div>
 
                </div>
            </div>
        </section>

        <Cta 
            title="Are you a React.js Developer in Australia?"
            text="If you feel that you fit the requirements above and want to work on exciting startup projects, please contact us."
        />
    </Layout>
)

export default ReactJobsPage