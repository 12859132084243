import React from "react";

function ChevronDown() {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7834 6.83218L15.0763 6.12504C14.9084 5.95718 14.637 5.95718 14.4692 6.12504L8.0013 12.5786L1.53344 6.12504C1.36558 5.95718 1.09415 5.95718 0.926297 6.12504L0.219155 6.83218C0.0512974 7.00004 0.0512974 7.27146 0.219155 7.43932L7.69773 14.9179C7.86558 15.0857 8.13701 15.0857 8.30487 14.9179L15.7834 7.43932C15.9513 7.27146 15.9513 7.00004 15.7834 6.83218V6.83218Z"
        fill=""
      />
    </svg>
  );
}

export default ChevronDown;